/*------------------------------------*\
    #Breakpoints
\*------------------------------------*/

// Small devices (landscape phones, less than 768px)
$mobile: 767.98px;

// Medium devices (tablets, less than 992px)
$tablet: 991.98px;

// Large devices (desktops, less than 1200px)
$desktop: 1199.98px;

// Large devices (desktops, less than 1650px)
$desktop-xl: 1650px;

// Large devices (desktops, more than 2400px)
$desktop-xxl: 2400px;
