@import 'palette';
@import 'breakpoints';

/*------------------------------------*\
    #Application global variables.
\*------------------------------------*/
// Set Font Awesome font path
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
// ---------------------------------------------------------------------------

@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
