/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

// Theme variables, must be included before the libraries to allow overriding defaults
@import 'theme/theme-variables';
@import 'theme/typo.scss';
@import 'theme/breakpoints.scss';
@import 'theme/theme-custom.scss';
@import 'theme/animations-custom.scss';
@import 'theme/global-variables.scss';

// 3rd party libraries
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/brands.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

// TODO: The styles obtained by the angular implementation are not displayed correctly. When they do, uncomment the following line.
// @import '~@vime/core/themes/default.css';

// Theme customization
@import 'theme/global';
