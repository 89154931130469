@import 'breakpoints';

/*------------------------------------*\
    Typography
\*------------------------------------*/
@font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/PolySans/PolySansTrial-Slim.ttf') format('truetype'),
        url('/assets/fonts/PolySans/PolySansTrial-Slim.woff') format('woff'),
        url('/assets/fonts/PolySans/PolySansTrial-Slim.woff2') format('woff2');
}

@font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/PolySans/PolySansTrial-Neutral.ttf') format('truetype'),
        url('/assets/fonts/PolySans/PolySansTrial-Neutral.woff') format('woff'),
        url('/assets/fonts/PolySans/PolySansTrial-Neutral.woff2') format('woff2');
}

@font-face {
    font-family: 'PolySans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/PolySans/PolySansTrial-Median.ttf') format('truetype'),
        url('/assets/fonts/PolySans/PolySansTrial-Median.woff') format('woff'),
        url('/assets/fonts/PolySans/PolySansTrial-Median.woff2') format('woff2');
}

@font-face {
    font-family: 'PolySans';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/PolySans/PolySansTrial-Bulky.ttf') format('truetype'),
        url('/assets/fonts/PolySans/PolySansTrial-Bulky.woff') format('woff'),
        url('/assets/fonts/PolySans/PolySansTrial-Bulky.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: bold;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-Regular.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-Regular.woff') format('woff');
}

@font-face {
    font-family: 'TTNorms';
    font-style: bold;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-Medium.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-Medium.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: bold;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-Medium.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-Medium.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: bold;
    font-weight: 600;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-Bold.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-Bold.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-ExtraBold.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-ExtraBold.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-ExtraBold.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: heavy;
    font-weight: 800;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-Heavy.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-Heavy.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-Heavy.woff2') format('woff2');
}

@font-face {
    font-family: 'TTNorms';
    font-style: heavy;
    font-weight: 900;
    font-display: swap;
    src: url('/assets/fonts/TTNorms/TTNorms-Heavy.ttf') format('truetype'),
        url('/assets/fonts/TTNorms/TTNorms-Heavy.woff') format('woff'),
        url('/assets/fonts/TTNorms/TTNorms-Heavy.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/assets/fonts/Inter/Inter-Regular.woff') format('woff'),
        url('/assets/fonts/Inter/Inter-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-style: bold;
    font-weight: 500;
    font-display: swap;
    src: url('/assets/fonts/Inter/Inter-Medium.woff') format('woff'),
        url('/assets/fonts/Inter/Inter-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Inter';
    font-style: bold;
    font-weight: 700;
    font-display: swap;
    src: url('/assets/fonts/Inter/Inter-Bold.woff') format('woff'),
        url('/assets/fonts/Inter/Inter-Bold.woff2') format('woff2');
}
